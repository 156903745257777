import { QueryKey } from 'react-query';
import {
  ApplicationUserTab,
  IssueViewModel,
  OrderPaymentViewModel,
  OrderTelematicsViewModel,
  QuotaListRequest,
  QuotaListResult,
  QuotaSortBy,
  ReportShipmentViewModel,
  ShipmentItemViewModel,
  ShipShipmentItemViewModel,
  SortOrder,
  ShipmentReportViewModel,
  PageOptions,
  ShipmentListRequestFilter,
  ShipmentListRequestSorting,
  ShipmentPagedList,
  AddNomenclatureIssuesViewModel,
  QuotaNomenclaturePriceDrops,
  ExternalQuotaFilter,
  ExternalQuotaSorting,
  ExternalQuotasPagedList,
  CreateScoringApprovalIssueViewModel,
  QuotaDealQualificationRuleViewModel,
  QuotaDealQualificationViewModel,
  QuotaMarginViewModel,
  QuotaMarginInputModel,
  ScoringRouteViewModel,
  ScoringModelRouteViewModel,
  QuotaCounterpartyViewModel,
  QuotaCounterpartyInnViewModel,
} from 'schema/serverTypes';
import {
  useBackendQuery,
  useBackendMutation,
  useBackendFormDataMutation,
  appendNumber,
  appendValue,
  QueryOptions,
  MutationOptions,
  MutationOptionsWithoutHttpMethod,
} from '.';
import { calculationUrl } from 'services/urls';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

const getMutationUrl = <TRequest>(relativeUrl: string | ((form: TRequest) => string)) => {
  if (typeof relativeUrl === 'string') {
    return `${calculationUrl}/api/v1/quotas/${relativeUrl}`;
  }
  return (form: TRequest) => `${calculationUrl}/api/v1/quotas/${relativeUrl(form)}`;
};

const getFormData = (values: ShipShipmentItemViewModel) => {
  var formData = new FormData();

  if (values.expectedShipmentDate && values.expectedShipmentDate !== '') {
    appendValue(formData, 'expectedShipmentDate', values.expectedShipmentDate);
  }
  if (values.shipmentDate && values.shipmentDate !== '') {
    appendValue(formData, 'shipmentDate', values.shipmentDate);
  }
  appendValue(formData, 'brand', values.brand);
  appendValue(formData, 'category', values.category);
  appendValue(formData, 'itemModel', values.itemModel);
  appendValue(formData, 'leaseSubject', values.leaseSubject);
  appendValue(formData, 'leaseSubjectInDocument', values.leaseSubjectInDocument);
  appendValue(formData, 'shipmentPlace', values.shipmentPlace);
  appendValue(formData, 'vin', values.vin);
  appendValue(formData, 'engineNumber', values.engineNumber);
  appendValue(formData, 'chassisNumber', values.chassisNumber);
  appendValue(formData, 'bodyNumber', values.bodyNumber);
  appendValue(formData, 'color', values.color);
  appendValue(formData, 'enginePower', values.enginePower);
  appendValue(formData, 'engineVolume', values.engineVolume);
  appendNumber(formData, 'technicalDevicePassport.Number', values.technicalDevicePassport?.number);
  appendValue(
    formData,
    'technicalDevicePassport.IssuedBy',
    values.technicalDevicePassport?.issuedBy
  );
  appendValue(
    formData,
    'technicalDevicePassport.IssuedDate',
    values.technicalDevicePassport?.issuedDate
  );
  appendNumber(formData, 'technicalDevicePassport.Price', values.technicalDevicePassport?.price);

  appendNumber(formData, 'dealerContact.Id', values.dealerContact?.id);
  appendValue(formData, 'dealerContact.FirstName', values.dealerContact?.firstName);
  appendValue(formData, 'dealerContact.LastName', values.dealerContact?.lastName);
  appendValue(formData, 'dealerContact.MiddleName', values.dealerContact?.middleName);
  appendValue(formData, 'dealerContact.Reason', values.dealerContact?.reason);
  appendValue(formData, 'dealerContact.Number', values.dealerContact?.number);
  appendValue(formData, 'dealerContact.Date', values.dealerContact?.date);

  appendNumber(formData, 'lesseeContact.Id', values.lesseeContact?.id);
  appendValue(formData, 'lesseeContact.FirstName', values.lesseeContact?.firstName);
  appendValue(formData, 'lesseeContact.LastName', values.lesseeContact?.lastName);
  appendValue(formData, 'lesseeContact.MiddleName', values.lesseeContact?.middleName);
  appendValue(formData, 'lesseeContact.Reason', values.lesseeContact?.reason);
  appendValue(formData, 'lesseeContact.Number', values.lesseeContact?.number);
  appendValue(formData, 'lesseeContact.Date', values.lesseeContact?.date);

  appendNumber(formData, 'lessorContact.Id', values.lessorContact?.id);
  appendValue(formData, 'lessorContact.FirstName', values.lessorContact?.firstName);
  appendValue(formData, 'lessorContact.LastName', values.lessorContact?.lastName);
  appendValue(formData, 'lessorContact.MiddleName', values.lessorContact?.middleName);
  appendValue(formData, 'lessorContact.Reason', values.lessorContact?.reason);
  appendValue(formData, 'lessorContact.Number', values.lessorContact?.number);
  appendValue(formData, 'lessorContact.Date', values.lessorContact?.date);

  appendValue(formData, 'fileUrl', values.fileUrl);

  if (values.file !== null && values.file !== undefined && values.file.size) {
    formData.append('file', values.file);
  }

  return formData;
};

export const useQuotasBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  relativeUrl: string,
  queryKey: TQueryKey,
  options?: QueryOptions<TResponse, TQueryKey>
) => useBackendQuery(`${calculationUrl}/api/v1/quotas/${relativeUrl}`, queryKey, options);

export const useShipShipmentItemMutation = (
  quotaId: number,
  shipmentItemId: number,
  options: any
) =>
  useBackendFormDataMutation<ShipShipmentItemViewModel, ShipmentItemViewModel>(
    `${calculationUrl}/api/v1/quotas/${quotaId}/shipment/item/${shipmentItemId}/ship`,
    getFormData,
    {
      method: 'PUT',
      ...options,
    }
  );

export const useReportShipmentMutation = (quotaId: number, shipmentItemId: number, options: any) =>
  useBackendMutation<ReportShipmentViewModel, IssueViewModel>(
    `${calculationUrl}/api/v1/quotas/${quotaId}/shipment/item/${shipmentItemId}/report`,
    {
      method: 'PUT',
      ...options,
    }
  );

export const useUpdateShipmentItemMutation = (
  quotaId: number,
  shipmentItemId: number,
  options?: any
) =>
  useBackendFormDataMutation<ShipShipmentItemViewModel, ShipmentItemViewModel>(
    `${calculationUrl}/api/v1/quotas/${quotaId}/shipment/item/${shipmentItemId}`,
    getFormData,
    {
      method: 'PUT',
      ...options,
    }
  );

export const useQuotasBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined
) => useBackendMutation(getMutationUrl(relativeUrl), options);

const createOptions = <TResponse>(options: QueryOptions<TResponse, string> | undefined = {}) => {
  const defaultOptions: QueryOptions<TResponse, string> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, string>;
};

export const useDictionaryBackendQuery = <TResponse>(
  relativeUrl: string,
  options?: QueryOptions<TResponse, string>
) =>
  useBackendQuery(
    `${calculationUrl}/api/v1/dictionaries/${relativeUrl}`,
    relativeUrl,
    createOptions(options)
  );

export const useDictionaryBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined
) => useBackendMutation(`${calculationUrl}/api/v1/dictionaries/${relativeUrl}`, options);

export const useOrderTelematicsMutation = (
  quotaId: number,
  shipmentItemId: number,
  options: MutationOptionsWithoutHttpMethod<OrderTelematicsViewModel, IssueViewModel> | undefined
) =>
  useQuotasBackendMutation(`${quotaId}/shipment/item/${shipmentItemId}`, {
    ...options,
    method: 'POST',
  });

export const useOrderPaymentMutation = (
  quotaId: number,
  options: MutationOptionsWithoutHttpMethod<OrderPaymentViewModel, IssueViewModel> | undefined
) => useQuotasBackendMutation(`${quotaId}/payment`, { ...options, method: 'POST' });

/**
 * Custom hook to create a mutation for scoring issues associated with a specific quota.
 *
 * @param quotaId - The unique identifier of the quota for which the issue is being created.
 * @param options - Optional parameters for configuring the mutation. These include settings like retries, caching, and HTTP method (though it's overridden here to 'POST').
 * @returns A function that can be used to trigger the mutation for creating a scoring issue.
 */
export const useCreateScoringIssueMutation = (
  quotaId: number,
  options: MutationOptionsWithoutHttpMethod<ScoringModelRouteViewModel, IssueViewModel> | undefined
) => useQuotasBackendMutation(`${quotaId}/scoring/issues`, { ...options, method: 'POST' });

/**
 * Custom hook to create a mutation for scoring approval issues associated with a specific quota.
 *
 * @param quotaId - The unique identifier of the quota for which the issue is being created.
 * @param options - Optional parameters for configuring the mutation. These include settings like retries, caching, and HTTP method (though it's overridden here to 'POST').
 * @returns A function that can be used to trigger the mutation for creating a scoring approval issue.
 */
export const useCreateScoringApprovalIssueMutation = (
  quotaId: number,
  options:
    | MutationOptionsWithoutHttpMethod<CreateScoringApprovalIssueViewModel, IssueViewModel>
    | undefined
) =>
  useBackendFormDataMutation(
    `${calculationUrl}/api/v1/quotas/${quotaId}/scoring/issues/approval`,
    (values) => {
      var formData = new FormData();

      formData.append('description', values.description);
      formData.append('file', values.file);

      return formData;
    },
    { ...options, method: 'POST' }
  );

export const useSetQuotaStatusMutation = (
  options?: any,
  data?: { reason: string; data: FieldValues; quotaId: string[]; comment: string }
) => useQuotasBackendMutation('status', { ...data, method: 'POST', ...options });

export const useQuotasListQuery = (request: QuotaListRequest) => {
  const {
    tabId = ApplicationUserTab.mine,
    dealerInn = [],
    lesseeInn = [],
    inn = [],
    search = '',
    ownerId = [],
    sortBy = QuotaSortBy.id,
    order = SortOrder.asc,
    hasExpectedShipmentDate = false,
    hasShipmentDate = false,
    leasingProduct = [],
    page = 1,
    pageSize = 20,
    status = [],
  } = request;

  const url = useMemo(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('tabId', tabId.toString());
    if (inn.length > 0) {
      inn.forEach((t) => searchParams.append('inn', t));
    }
    if (dealerInn.length > 0) {
      dealerInn.forEach((t) => searchParams.append('dealerInn', t));
    }
    if (lesseeInn.length > 0) {
      lesseeInn.forEach((t) => searchParams.append('lesseeInn', t));
    }
    if (ownerId.length > 0) {
      ownerId.forEach((t) => searchParams.append('ownerId', t));
    }
    if (leasingProduct.length > 0) {
      leasingProduct.forEach((t) => searchParams.append('leasingProduct', t));
    }
    if (search !== '') {
      searchParams.set('search', search);
    }
    if (hasExpectedShipmentDate) {
      searchParams.set('hasExpectedShipmentDate', hasExpectedShipmentDate.toString());
    }
    if (hasShipmentDate) {
      searchParams.set('hasShipmentDate', hasShipmentDate.toString());
    }
    if (status.length > 0) {
      status.forEach((t) => searchParams.append('status', t));
    }

    searchParams.set('sortBy', sortBy);
    searchParams.set('order', order);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    return `${calculationUrl}/api/v1/quotas?${searchParams}`;
  }, [
    tabId,
    dealerInn,
    lesseeInn,
    inn,
    search,
    ownerId,
    sortBy,
    order,
    hasExpectedShipmentDate,
    hasShipmentDate,
    leasingProduct,
    page,
    pageSize,
    status,
  ]);

  return useBackendQuery<QuotaListResult>(url, ['quotas', url]);
};

export const useShipmentReportQuery = (
  quotaId: number,
  options?: QueryOptions<ShipmentReportViewModel, string>
) => useQuotasBackendQuery(`${quotaId}/shipment/report`, `${quotaId}/shipment/report`, options);

export const usePriceDropsQuery = (
  quotaId: number,
  options?: QueryOptions<QuotaNomenclaturePriceDrops, string>
) => useQuotasBackendQuery(`${quotaId}/priceDrops`, `${quotaId}/priceDrops`, options);

export const useScoringQuery = (
  quotaId: number,
  options?: QueryOptions<ScoringRouteViewModel, string>
) => useQuotasBackendQuery(`${quotaId}/scoring`, `${quotaId}/scoring`, options);

export type UseShipmentsListQueryProps = ShipmentListRequestFilter &
  Partial<ShipmentListRequestSorting> &
  PageOptions;

export const useShipmentsListQuery = (request: UseShipmentsListQueryProps) => {
  const {
    from = '',
    to = '',
    sortBy = QuotaSortBy.id,
    order = SortOrder.asc,
    page = 1,
    pageSize = 20,
  } = request;

  const url = useMemo(() => {
    const searchParams = new URLSearchParams();

    if (from !== '') {
      searchParams.set('from', from);
    }

    if (to !== '') {
      searchParams.set('to', to);
    }

    searchParams.set('sortBy', sortBy);
    searchParams.set('order', order);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    return `${calculationUrl}/api/v1/quotas/shipments?${searchParams}`;
  }, [from, to, sortBy, order, page, pageSize]);

  return useBackendQuery<ShipmentPagedList>(url, url);
};

export const useAddNomenclatureIssueMutation = (
  quotaId: number,
  options:
    | MutationOptionsWithoutHttpMethod<AddNomenclatureIssuesViewModel, IssueViewModel[]>
    | undefined
) =>
  useQuotasBackendMutation(`${quotaId}/nomenclatures`, {
    ...options,
    method: 'POST',
  });

export const useNotifyContractExecutionMutation = (quotaId: number, options?: any) =>
  useBackendMutation(`${calculationUrl}/api/v1/quotas/${quotaId}/contract/notify`, {
    method: 'PUT',
    ...options,
  });

export type UseExternalQuotasQueryProps = {
  filter: ExternalQuotaFilter;
  sorting: ExternalQuotaSorting;
  paging: PageOptions;
};

export const useExternalQuotasQuery = (props: UseExternalQuotasQueryProps) => {
  const { filter, sorting, paging } = props;

  const {
    clientId = [],
    lesseeInn = [],
    dealerInn = [],
    region = [],
    userId = [],
    status,
    tabId,
  } = filter;

  const { sortBy, order } = sorting;

  const { page = 1, pageSize = 20 } = paging;

  const url = useMemo(() => {
    const searchParams = new URLSearchParams();

    if (region.length > 0) {
      region.forEach((t) => searchParams.append('region', t));
    }

    if (userId.length > 0) {
      userId.forEach((t) => searchParams.append('userId', t.id));
    }

    if (tabId !== undefined) {
      searchParams.set('tabId', tabId);
    }

    if (clientId.length > 0) {
      clientId.forEach((t) => searchParams.append('clientId', t));
    }

    if (lesseeInn.length > 0) {
      lesseeInn.forEach((t) => searchParams.append('lesseeInn', t));
    }

    if (dealerInn.length > 0) {
      dealerInn.forEach((t) => searchParams.append('dealerInn', t));
    }

    if (status !== undefined) {
      searchParams.set('status', status);
    }

    searchParams.set('sortBy', sortBy);
    searchParams.set('order', order);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    return `${calculationUrl}/api/v1/quotas/external?${searchParams}`;
  }, [
    sortBy,
    order,
    page,
    pageSize,
    clientId,
    dealerInn,
    lesseeInn,
    status,
    region,
    tabId,
    userId,
  ]);

  return useBackendQuery<ExternalQuotasPagedList>(url, url);
};

export const useQuotaDealQualificationQuery = (quotaId: number) =>
  useQuotasBackendQuery<QuotaDealQualificationRuleViewModel[]>(
    `${quotaId}/dealQualifications`,
    `${quotaId}/dealQualifications`,
    { refetchOnMount: true }
  );

export const useUpdateQuotaDealQualificationMutation = (
  quotaId: number,
  options?: MutationOptionsWithoutHttpMethod<
    QuotaDealQualificationViewModel[],
    QuotaDealQualificationRuleViewModel[]
  >
) =>
  useQuotasBackendMutation(`${quotaId}/dealQualifications`, {
    ...options,
    method: 'PUT',
  });

export const useQuotaMarginDetailsQuery = (quotaId: number) => {
  const url = `${quotaId}/margin`;
  return useQuotasBackendQuery<QuotaMarginViewModel>(url, url, { refetchOnMount: true });
};

export const useUpdateQuotaMarginMutation = (
  quotaId: number,
  options?: MutationOptions<QuotaMarginInputModel, QuotaMarginViewModel>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/quotas/${quotaId}/margin`, {
    method: 'PUT',
    ...options,
  });

export const useQuotaCounterpatiesQuery = (quotaId: number) => {
  const url = `${quotaId}/counterparties`;
  return useQuotasBackendQuery<QuotaCounterpartyViewModel[]>(url, url, { refetchOnMount: true });
};

export const useRequestCounterpartyRatingRiskMutation = (
  quotaId: number,
  options?: MutationOptions<QuotaCounterpartyInnViewModel, IssueViewModel>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/quotas/${quotaId}/counterparties/issues`, {
    method: 'POST',
    ...options,
  });
